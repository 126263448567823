export default () => {
  // iterate sonic tables
  [...document.querySelectorAll('.sonic-table')].forEach((table) => {
    const thead = table.querySelector('thead');
    const tbody = table.querySelector('tbody');
    const headText = [];
    let headCount = 0;

    // return, no thead or tbody
    if (!thead || !tbody) {
      return;
    }

    // iterate table header cells
    [...thead.querySelectorAll('th')].forEach((header) => {
      const text = header.innerText;
      const colspan = header.hasAttribute('colspan') && header.getAttribute('colspan') > 1
        ? header.getAttribute('colspan')
        : 0;

      // header text
      headText[headCount] = text;

      // header cells part of colspans
      if (colspan) {
        for (let j = 1; j < colspan; j += 1) {
          headCount += 1;
          headText[headCount] = text;
        }
      }

      // header increment
      headCount += 1;
    });

    // iterate table body rows
    [...tbody.querySelectorAll('tr')].forEach((tr) => {
      let cellCount = 0;

      // iterate table body cells
      [...tr.querySelectorAll('th, td')].forEach((cell) => {
        const colspan = cell.hasAttribute('colspan') && cell.getAttribute('colspan') > 1
          ? cell.getAttribute('colspan')
          : 0;

        // Add header as data-title.
        cell.setAttribute('data-title', headText[cellCount]);

        // th title colspans.
        if (colspan) {
          for (let j = 1; j < colspan; j += 1) {
            cellCount += 1;
            cell.setAttribute(
              'data-title',
              `${cell.getAttribute('data-title')}, ${headText[cellCount]}`,
            );
          }
        }

        // cell increment
        cellCount += 1;
      });
    });
  });
};
