export default () => {
  if (window && window.newrelic) {
    // eslint-disable-next-line no-underscore-dangle
    window.newrelic.setCustomAttribute('cohesionSessionId', window._Cohesion.sessionId);
  }

  window.addEventListener('load', () => {
    // return, no HE_UCL on page
    if (!window.newrelic) {
      return;
    }

    if (!window.HE_UCL) {
      window.newrelic.setCustomAttribute('uclOnWindow', 'false');
      return;
    }

    window.newrelic.setCustomAttribute('uclOnWindow', 'true');

    // find page components based on manifest
    const selectors = Object.keys(window.HE_UCL.manifest).join(', ');
    const components = document.querySelectorAll(selectors);

    // NR Tracking: individual component loads
    components.forEach((component) => {
      const componentName = component.tagName.toLowerCase();
      const fileName = `${window.HE_UCL.manifest[componentName].component || componentName}.js`;
      if (window.HE_UCL.loaded.includes(fileName)) {
        window.newrelic.setCustomAttribute(`componentLoaded-${componentName}`, 'true');
      } else {
        window.newrelic.setCustomAttribute(`componentLoaded-${componentName}`, 'false');
      }
    });

    // NR Tracking: no components loaded
    if (components.length && window.HE_UCL.loaded.length === 0) {
      window.newrelic.setCustomAttribute('componentsLoaded', 'false');
    } else {
      window.newrelic.setCustomAttribute('componentsLoaded', 'true');
    }
  });
};
