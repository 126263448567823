const loadLottiePlayer = (className) => {
  const lottie = document.querySelector(className);

  if (lottie) {
    const script = document.createElement('script');
    script.src = 'https://unpkg.com/@lottiefiles/lottie-player@2.0.4/dist/lottie-player.js';
    document.head.appendChild(script);
  }
};

export default loadLottiePlayer;
