import { v4 as uuid } from 'uuid';
import tagularEvent from '../tagular/event';

export default () => {
  // once everything is loaded, scan page for elements that contain tracking attributes
  window.addEventListener('DOMContentLoaded', () => {
    // grab all elements with tracking info
    const trackingItems = document.querySelectorAll('[data-tracking]');

    // if no tracking items found, exit function
    if (!trackingItems || trackingItems.length === 0) return;

    // loop through tracking items and set up click events
    trackingItems.forEach((item) => {
      if (item.dataset?.tracking && item.dataset.tracking !== 'null') {
        const {
          beam,
          event,
          data,
          actionOutcome,
          outboundUrl,
          viewEvent,
          log = false,
        } = JSON.parse(item.dataset.tracking);
        const correlationId = event === 'click' ? uuid() : null;
        const url = item.hasAttribute('href') ? new URL(item.href) : null;

        if (data.name === 'voyager_entry' && url != null) {
          url.searchParams.append('elementClickID', correlationId);
          // eslint-disable-next-line no-param-reassign
          item.href = url;
        }

        item.addEventListener(event, () => {
          tagularEvent(beam, {
            actionOutcome, webElement: data, correlationId, outboundUrl
          }, log);
        });

        if (viewEvent) {
          let itemViewed = false;
          if ('IntersectionObserver' in window) {
            const observer = new IntersectionObserver(
              (entries) => {
                if (entries[0].isIntersecting && !itemViewed) {
                  tagularEvent('ElementViewed', { webElement: data }, log);
                  itemViewed = true;
                }
              },
              {
                threshold: 0.5,
              },
            );
            observer.observe(item);
          }
        }
      }
    });
  });

  window.cohesion('ready', () => {
    window.tagular('setAliasQuery', '.js-transit-link');
  });
};
