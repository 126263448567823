export default () => {
  const navigation = document.querySelector('[data-sonic="navigation"]');

  // return, if template does not have navigation.
  if (!navigation) {
    return;
  }

  // handle dark variant stuff
  const darkVariant = document.querySelector(
    '.dark-variant[data-sonic="navigation"]'
  );
  const hasDarkVariant = !!darkVariant;

  // handle when we have internal navs too
  const internalNav = document.querySelector('.internal-nav');
  const hasInternalNav = !!internalNav;

  // handle when we have auto complete program finder
  const programFinder = document.querySelector('.acpf');
  const hasProgramFinder = !!programFinder;

  // bindings
  const { body } = document;
  const banner = document.querySelector('[data-sonic-event-point="banner"]');
  const label = banner.querySelector('[data-sonic-event-point="label"]');
  let prevOffset = window.pageYOffset < 0 ? 0 : window.pageYOffset;

  // Menu Button: Delegated event for Menu button click
  const eventMenuButton = () => {
    body.classList.toggle('is-menu-nav-active');
  };

  // Back Button: Delegated event for back button click
  const eventBackButton = () => {
    const primary = document.querySelector(
      '[data-sonic-event-point="primary-link"].is-active'
    );
    const primaryTarget = primary.querySelector(
      '[data-sonic-event="primary-link"]'
    );
    const secondary = document.querySelector(
      '[data-sonic-event-point="secondary-link"].is-active'
    );

    if (secondary) {
      secondary.classList.remove('is-active');
      label.innerText = primaryTarget.innerText;
    } else if (primary) {
      body.classList.remove('is-secondary-menu-nav-active');
      primary.classList.remove('is-active');
      banner.classList.remove('is-active');
      label.innerText = '';
    }
  };

  // Close Button: Delegated event for close button click
  const eventCloseButton = () => {
    // Remove Mobile Navigation Active
    body.classList.remove('is-menu-nav-active');
    body.classList.remove('is-secondary-menu-nav-active');

    // Remove all navigaiton "is-active" classes
    const activeElements = navigation.querySelectorAll('.is-active');
    activeElements.forEach((el) => {
      el.classList.remove('is-active');
    });

    // Reset label
    label.innerText = '';
  };

  // Primary Link: Delegated event for primary link click
  const eventPrimaryLink = (ev) => {
    const action = ev.target.closest('[data-sonic-event-point="primary-link"]');
    body.classList.add('is-secondary-menu-nav-active');
    action.classList.add('is-active');
    banner.classList.add('is-active');
    label.innerText = ev.target.innerText;
  };

  // Secondary Link: Delegated event for secondary link click
  const eventSecondaryLink = (ev) => {
    const action = ev.target.closest(
      '[data-sonic-event-point="secondary-link"]'
    );

    label.innerText = ev.target.innerText;
    action.classList.add('is-active');
  };

  // Events: Navigation Delegation Click
  navigation.addEventListener('click', (ev) => {
    // Return if desktop or no trigger attached to dom element
    if (
      !ev.target.dataset ||
      !ev.target.dataset.sonicEvent ||
      window.innerWidth >= 1024
    ) {
      return;
    }

    // Prevent defaults
    ev.preventDefault();

    // Events
    switch (ev.target.dataset.sonicEvent) {
      case 'menu-button':
        eventMenuButton();
        break;
      case 'back-button':
        eventBackButton();
        break;
      case 'close-button':
        eventCloseButton();
        break;
      case 'primary-link':
        eventPrimaryLink(ev);
        break;
      case 'secondary-link':
        eventSecondaryLink(ev);
        break;
      default:
        break;
    }
  });

  // On scroll down, hide navigation, on scroll up display sticky navigation
  const scroll = () => {
    const currentOffset = window.pageYOffset < 0 ? 0 : window.pageYOffset;

    window.requestAnimationFrame(() => {
      navigation.classList.toggle(
        'scrolling-up',
        currentOffset > 10 && currentOffset < prevOffset
      );
      navigation.classList.toggle(
        'scrolling-down',
        currentOffset > 10 && currentOffset > prevOffset
      );

      if (hasDarkVariant) {
        navigation.classList.toggle('un-dark', currentOffset > 120);
      }

      if (hasInternalNav) {
        internalNav.classList.toggle(
          'scrolling-up',
          currentOffset > 10 && currentOffset < prevOffset
        );
      }

      if (hasProgramFinder) {
        programFinder.classList.toggle(
          'scrolling-up',
          currentOffset > 10 && currentOffset < prevOffset
        );
      }

      prevOffset = currentOffset;
    });
  };

  // Initialize scroll event
  window.addEventListener('scroll', scroll);

  // Accessibility for the dropdown menus, do keyboard navigation
  const dropdowns = document.querySelectorAll(
    '[data-sonic-event-point="primary-link"]'
  );
  const closeNavMenu = () => {
    const elOpenedMenu = navigation.querySelector('.sr-open');
    const elCarretButton = elOpenedMenu.querySelector('button.caret');

    elOpenedMenu?.classList?.remove('sr-open');
    elCarretButton.ariaExpanded = elCarretButton.ariaExpanded !== 'true';
  };

  if (dropdowns) {
    Array.prototype.forEach.call(dropdowns, (el) => {
      const elPrimaryItem = el.querySelector('.primary-item ');
      const elButton = el.querySelector('button');
      if (elButton) {
        elButton.addEventListener('keyup', (e) => {
          const parentLi = e.currentTarget.closest('li');

          if (e.keyCode === 32 || e.keyCode === 13) {
            // if they hit the spacebar or return key
            e.preventDefault();
            const elOpenedMenu = navigation.querySelector('.sr-open');
            if (!elOpenedMenu?.isSameNode(parentLi)) {
              elOpenedMenu?.classList?.remove('sr-open');
            }

            parentLi.classList.toggle('sr-open');
            elButton.ariaExpanded = elButton.ariaExpanded !== 'true';
          }
        });
      }

      elPrimaryItem.addEventListener('focusin', () => {
        // Close menu when focus is outside of the sub-navigation
        closeNavMenu();
      });
    });

    document.addEventListener('keyup', (e) => {
      if (e.keyCode === 27) {
        // Close menu when escape key pressed
        closeNavMenu();
      }
    });
  }
};
