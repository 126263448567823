/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-unresolved */
import newrelic from './vendor/new-relic';
import header from './partials/header';
import table from './components/table';
import tracking from './utils/tracking';
import monarchPlacements from './partials/monarch';
import stripClass from './partials/strip-class';
import loadLottiePlayer from './components/lottie';

(() => {
  table();

  // Tracking
  tracking();

  // Globals
  header();

  // Vendor
  newrelic();

  window.cohesion('monarch:ready', () => {
    const monetizationOff = document.querySelector('.js-qdf-off') !== null;
    monarchPlacements(
      'studyMatchOptimizedPlacementPostP2',
      '[data-monarch="placement-post-atf"]',
      {
        monetization: {
          off: monetizationOff,
        },
      }
    );
  });

  window.addEventListener('monarch:studyMatchOptimizedPlacementPostP2:done', () => {
    stripClass('monarchjs');

    // We currenlty only use lottie for the animiated entry point
    loadLottiePlayer('.js-lottie');
  });

  window.cohesion('preamp:done', () => {
    stripClass('preampjs');
  });
})();
