const addBodyScript = (bodyScript) => {
  const transitLinks = document.querySelectorAll('.js-transit-link');
  if (transitLinks.length > 0) {
    transitLinks.forEach((link) => {
      if (link.href.indexOf('transit_id') === -1) {
        link.classList.add('js-transit-monarch');
      }
    });
  }
  const script = document.createElement('script');
  script.async = true;
  script.innerText = `${bodyScript} window.tagular('setAliasQuery', '.js-transit-monarch')`;
  script.async = 1;
  document.body.appendChild(script);
};

const showAsset = (placementClass, asset) => {
  const placement = document.querySelector(placementClass);
  const hiddenElements = document.querySelectorAll('[data-monarch]');
  if (!placement) return;
  const { html, js, css } = asset;
  if (html) placement.innerHTML = html;
  placement.insertAdjacentHTML(
    'afterbegin',
    `${css ? `<style>${css}</style>` : ''}`,
  );
  if (js) addBodyScript(js);
  hiddenElements.forEach((el) => {
    el.removeAttribute('data-monarch');
  });
};

const monarchPlacements = (ruleCustomID, placementClass, payload = {}) => {
  window.monarch('rule', ruleCustomID, payload, {}, (err, result) => {
    if (err) return;
    const monarchDone = new CustomEvent(`monarch:${ruleCustomID}:done`);
    if (result != null && result[ruleCustomID] != null) {
      showAsset(placementClass, result[ruleCustomID]);
      window.dispatchEvent(monarchDone);
    }
  });
};

export default monarchPlacements;
